import Squad from './squad_trip.js'
import './styles/list_squads.css';


const ListSquads = ({detailsButton=true, ...props}) => {


    return (
        <div className="listsquads">
            {

                props.listSquads.map((squad) => {
                    return <Squad data={squad} key={squad["id"]} detailsButton={detailsButton}/>
                })
            }

        </div>
    )

}

export default  ListSquads ;