import './styles/user.css';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

const User  = (props) => {
    const data = props.data;
    const class_name = "user ".concat(props.index % 2 ===0 ? "even" : "odd");
    return (
        <div className={class_name}>

            <div className="barre_haut"><EmojiPeopleIcon /><span>{data["firstName"]}  {data["lastName"]}</span></div>
                    <div className="contenu_user">
                        <p>Tel: {data.phone==="" || data.phone===null? <span>Non renseigné</span> : data["phone"]}</p>
                        <p>Adresse e-mail: <a style={{color: '#fff', textDecoration: 'underline'}} href={'mailto:'+data["email"]}>{data["email"]}</a></p>
                    </div>
        </div>
    );


}

export default User;