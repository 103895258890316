
import React, {  useEffect, useState } from 'react';
import Banner from './../components/banner.js';
import './view.css';

const Page404 = (props) => {

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
        }

    useEffect( () => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize',updateDimensions);
    })

    return (

        <div className='image_campus'>
            <Banner showTopNavMenu = {width > 775} id="nav_in_banner"/>
            <br /><br /><br /><br />
            <div className="default_text_black">
                Error 404 not found
        </div>
        </div>

    )
}

export default Page404;

