import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import Banner from './../components/banner.js';
import { Dropdown } from 'semantic-ui-react'
import './view.css';
import { makeStyles} from '@material-ui/core/styles';
import 'date-fns';
import { local_time_to_UTC, verifyConnection } from './../utils.js'


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


const Propose = (props) => {
    const [formState, setForm] = useState({ destination: "", departure: "", place_available: 1, date: "", time: "", description: "", price:0, keyCar: -1 });
    const [squadInfo, setSquadInfo] = useState({type:"", token:"", id:""})
    const classes = useStyles();
    const query = new URLSearchParams(useLocation().search);

    const [dropdownOptions, setOptions] = useState([{
        key: 0,
        text: 'Nouvelle voiture',
        value: 0
    }]);

    const [car, setCar] = useState({ model: '', color: '', immatriculation: '', saved: false });
    const [listCars, setListCars] = useState([])
    const [loading, setLoading] = useState(true);
    const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken'))
        .split('=')[1];

    const submitNewCar = () => {
        return new Promise((resolve, reject) => {
            if (formState.keyCar !== -1) {
                resolve(listCars[formState.keyCar].id);
            } else {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
                    body: JSON.stringify({ ...car, place_available: formState.place_available }),
                    credentials: 'include'
                };
                const url = process.env.REACT_APP_API_URL + '/car/createCar/'
                // console.log("sending new car", car, " to:", url);
                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then(function (data) {
                        // console.log("Response:", data.data)
                        verifyConnection({data, triggerError: reject, triggerOK: resolve, paramsOK: data.data["idCar"]})
                    })
                    .catch((err) => console.log(err))
            }
        })
    }

    const submit = (e) => {
        e.preventDefault()//pour que ça ne refersh pas la page
        const datetimeUTC = local_time_to_UTC(formState["date"], formState["time"]);
        const formToSend = formState;
        formToSend.date = datetimeUTC.date;
        formToSend.time = datetimeUTC.time;
        formToSend.place_available = Math.floor(formState.place_available)
        submitNewCar()
            .then(idCar => {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
                    body: JSON.stringify({ ...formToSend, car: idCar, squadId: squadInfo.id, token: squadInfo.token, type: squadInfo.type }),
                    credentials: 'include'
                };
                // console.log("envoi de :", { ...formToSend, car: idCar });
                fetch(process.env.REACT_APP_API_URL + '/trip/createTrip/', requestOptions)
                    .then(response => response.json())
                    .then(function (data) {
                        // console.log("Response:", data.data)
                        if ("status" in data && data["status"] === 400) {
                            //Verify form et surlignage rouge là où il faut
                        }
                        verifyConnection({data, nextPage:(process.env.REACT_APP_CLIENT_URL + '/myTrips')})
                    })
                    .catch((err) => console.log(err))
            })

    }





    const handleFormUpdate = (event, key, value = null) => {
        setForm(prevForm => {
            var updatedValues = {};
            if (key === 'car') {
                updatedValues['keyCar'] = event.value;
                if (event.value === -1) {
                    updatedValues['place_available'] = 1;
                } else {
                    updatedValues['place_available'] = listCars[event.value].place_available;
                }
            } else {
                updatedValues[key] = event.target.value;
            }
            return { ...prevForm, ...updatedValues };
        });
    }

    const handleCar = (event, key) => {

        setCar(prev => {
            var updatedValues = {};
            if (key === "saved") {
                return { ...prev, saved: !car["saved"] }
            } else {
                updatedValues[key] = event.target.value;
                if (!(key === 'immatriculation' && updatedValues.immatriculation.length > 2)) {
                    return { ...prev, ...updatedValues };
                } else {
                    return prev;
                }
            }
        })
    }

    const getCars = () => {
        const url2 = process.env.REACT_APP_API_URL + '/user/carsSaved/me';
        // console.log("fetching to", url2)
        fetch(url2, { method: 'GET', credentials: 'include', mode: 'cors' })
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data.data)
                if (verifyConnection({data})) {
                    var l = [{ key: -1, text: 'Créer une voiture', value: -1 }];
                    setListCars(data.data)
                    var count = 0;
                    data.data.forEach(e => {
                        l.push({ key: e["id"], text: e["model"] + ' ' + e['color'] + ' ( XX - XXX - ' + e['immatriculation'] + ')', value: count });
                        count += 1;
                    });
                    setOptions(l);
                    setLoading(false);
                }
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        getCars();
        setSquadInfo({token: query.get("token"), id: query.get("squad"), type: query.get("type")});
    }, []);

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    })

    return (

        <div className='image_campus'>
            <Banner  showTopNavMenu={width > 775} id="nav_in_banner"/>
            <div className='white_layer'>
                <br/><br/>
                <h1 className="main">Proposer un trajet</h1>
                <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                <form>
                    <div className='block_form_hori'>
                        <div className="block_form_vert">
                            <label className="default_text_black">Point de départ</label>
                            <input className='input_text' placeholder="Gif-sur-Yvette" type='text' value={formState["departure"]} onChange={(event) => handleFormUpdate(event, "departure")} />
                        </div>
                        <span className="default_text_black">---{'>'}</span>
                        <div className="block_form_vert">
                            <label className="default_text_black">Destination</label>
                            <input className='input_text' placeholder="Lyon" type="text" id="demo-customized-textbox" value={formState["destination"]} onChange={(event) => handleFormUpdate(event, "destination")} />
                        </div>

                    </div>
                    <div className="block_form_hori">
                        <div className="block_form_vert">
                            <label className="default_text_black">Date</label>
                            <div className="wrap_input">
                                <input id="date" type="date" color="primary" className={classes.textField} InputLabelProps={{ shrink: true, }} value={formState["date"]} onChange={(event) => handleFormUpdate(event, "date")} />
                            </div>
                        </div>
                        <div  className="block_form_vert">
                            <label className="default_text_black">Heure de départ</label>
                            <div className="wrap_input">
                                <input id="time" type="time" color="primary" className={classes.textField} InputLabelProps={{ shrink: true, }} inputProps={{ step: 300 }} value={formState["time"]} onChange={(event) => handleFormUpdate(event, "time")} />
                            </div>
                        </div>
                    </div>

                    <div className="block_form_vert">
                            <label className="default_text_black">Prix</label>
                            <input type='number' value={formState["price"]} onChange={(event) => handleFormUpdate(event, "price")} />
                    </div>

                    <div className="block_form_vert multilineBlock">
                            <label className="default_text_black">Description (étapes intermédaires etc.)</label>
                            <textarea id="multiline" placeholder="" className='input_text' value={formState["description"]} onChange={(event) => handleFormUpdate(event, "description")} />
                    </div>

                    <div className="block_car" id="Voiture">
                        <h2 style={{display: "block", border: 'none', margin: 0}}>Choisir une voiture </h2>
                        {!loading &&
                            <div id="carac_car">
                                <label className="default_text_black">Voiture</label>
                                <Dropdown defaultValue={-1} fluid selection options={dropdownOptions} onChange={(value, event) => handleFormUpdate(event, "car")} />
                                <br />
                                {formState['keyCar'] === -1 && <div>
                                    <label className="default_text_black">Modèle : </label>
                                    <input placeholder={"Audi A3"} value={car["model"]} onChange={(event) => handleCar(event, "model")} />
                                    <br /><br />
                                    <label className="default_text_black">Couleur : </label>
                                    <input placeholder="Vert" value={car["color"]} onChange={(event) => handleCar(event, "color")} />
                                    <br /><br />
                                    <label className="default_text_black">2 dernières lettres de l'immatriculation : </label>
                                    <input placeholder={"AF"} value={car["immatriculation"]} onChange={(event) => handleCar(event, "immatriculation")} />
                                    <br /><br />
                                    <label className="default_text_black">Sauvegarder la voiture ? </label>
                                    <input type="checkbox" disabled={formState['keyCar'] !== -1} checked={car["saved"]} onChange={(event) => handleCar(event, "saved")} />
                                    <br /><br />
                                </div>}
                                <label className="default_text_black">Nombre de places disponibles</label>
                                <input type='number' step="1" min="1" defaultValue={formState['keyCar'] !== -1 ? listCars[formState['keyCar']]['place_available'] : 1} value={formState["place_available"]} onChange={(event) => handleFormUpdate(event, "place_available")} />

                            </div>
                        }
                    </div>
                    <div>
                        <button className="button_form" onClick={(e) => submit(e)}>
                            Enregistrer
                        </button>
                    </div>
                </form>



            </div>
        </div>
    );
}

export default Propose;

