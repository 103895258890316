import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './styles/squad_trip.css';
import { withRouter } from "react-router";
import { UTC_to_local_time } from './../utils.js'




const SquadTrip= (props) => {
    const data = props.data;
    const localDateS = UTC_to_local_time(data["date"]);
    const details = () => {
        props.history.push("/squad/" + data["id"]);
    }

    return (
        <div className="squad">
            <div className="barre_haut">
                <ul className="places default_text_black">
                    {data["title"]}
                </ul>
            </div>
            <div className="contenu">
                <ul>
                    Le : {localDateS[0]}
                </ul>
                <ul className="separator"></ul>
                {data["description"].length > 0 && <ul className="description">
                    Description : {data["description"].length < 200 ||  !props.detailsButton ? data["description"] : data["description"].slice(0, 200)+'...'}
                </ul>}
                {props.detailsButton && <button className={"button_paps"} onClick={(e) => details(e)}>Détails</button>}
            </div>
        </div>
    );


}

export default withRouter(SquadTrip);