import React, {  useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {useLocation} from "react-router-dom";
import Banner from './../components/banner.js';
import ListTrips from './../components/list_trips.js';
import Visibility from '@material-ui/icons/Visibility';
import LinkIcon from '@material-ui/icons/Link';


import './view.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import { verifyConnection } from '../utils.js';



const DetailsSquad = (props) => {
    const {idSquad} =useParams();
    const [userId, setUserID] = useState(-1);
    const [token, setToken] = useState("");
    const query = new URLSearchParams(useLocation().search);
    const [squad, setSquad] = useState({});
    const [tripsGoing, setTripsGoing] = useState([]);
    const [tripsComing, setTripsComing] = useState([]);
    const [link, setLink] = useState({copied: false, value:""});
    const [loadings, setLoadings] = useState({going: true, squad: true, coming: true});
    const [isModerator, setIsModerator] = useState(false);
    const [unallowed, setUnallowed] = useState(false);


    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        credentials: 'include'
    };


    useEffect(() => {
        setToken(query.get("token"));
        if (userId !== -1) {
            getData();
        }
    }, [userId]);

    const getData = () => {
        getSquad()
        .then((data) => {
            getTrips("Going");
            if(data.goingAndComing){
                getTrips("Coming");
            }

            if (data.moderators.includes(userId)) {
                getToken();
                setIsModerator(true);
            }
        })
        .catch((error) => {
            setUnallowed(true);
        })
    }

    const getTrips = (type) => {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL+'/squad/trips'+type+'/'+String(idSquad)+'?token=' + token;//Fetch the differents trips
            fetch(url, requestOptions)
            .then(function(response) {
                return response.json();

            })
            .then(function(data){
                if(type==='Going'){
                    verifyConnection({data, triggerOK: (trips) => {setTripsGoing(trips); resolve(trips); setLoadings((prevState) => {return {...prevState, going:false}});}, paramsOK: data.data, triggerError: reject});
                } else {
                    verifyConnection({data, triggerOK: (trips) => {setTripsComing(trips); resolve(trips); setLoadings((prevState) => {return {...prevState, coming:false}});}, paramsOK: data.data, triggerError: reject});
                }

            });
        });
    };

    const getToken = () => {
        const url = process.env.REACT_APP_API_URL+'/squad/token/'+String(idSquad)+'?token=' + token;//Fetch the token of the squad
        fetch(url, requestOptions)
        .then(function(response) {
            return response.json();

        })
        .then(function(data){
            verifyConnection({data, triggerOK: (token) => {setLink((prevState) => {return {...prevState, value: process.env.REACT_APP_CLIENT_URL+'/squad/'+idSquad+'?token='+token};});}, paramsOK: data.data});
        });

    }
    const handleNewTrip = (type) => {
        props.history.push('/propose?squad='+idSquad+'&type='+type+'&token='+token);
    }

    const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken'))
        .split('=')[1];

    const confirmDelete = (e) => {
        confirmAlert({
            title: 'Confirme la suppression',
            message: 'Veux tu vraiment supprimer ce squad trip?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => deleteSquad(e),
              },
              {
                label: 'Non',
                onClick: () => {},
              }
            ]
          });
    };

    const deleteSquad = () => {
        console.log("deleted")
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json',  'X-CSRFToken': csrfToken},
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_API_URL+'/squad/deleteSquad/'+String(idSquad)+'/', requestOptions)
            .then(function(response){return response.json()})
            .then(function(data){
                verifyConnection({data, nextPage: (process.env.REACT_APP_CLIENT_URL+'/mySquads')});
            } );
    }

    const getSquad = () => {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL+'/squad/details/'+String(idSquad)+'?token='+token;//Fetch the squad details
            fetch(url, requestOptions)
            .then(function(response) {
                return response.json();

            })
            .then(function(data){
                verifyConnection({data, triggerOK: (squad) => {setSquad(squad); resolve(squad); setLoadings((prevState) => {return {...prevState, squad:false}});}, paramsOK: data.data, triggerError: reject});
            });
        });
    };

    const verifyUser = (id) => {
        setUserID(id)
    }

    const copyLink = () => {
        setLink((prevState) => {return {...prevState, copied: true}});
        navigator.clipboard.writeText(link.value)
    }

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
        }

    useEffect( () => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize',updateDimensions);
    })



    return (
        <div className='image_campus'>
            <Banner verifyUser={(args) => verifyUser(args)} showTopNavMenu = {width > 775} id="nav_in_banner"/>
            <div className="white_layer">
                {unallowed ? <span>Hm tu n'as pas le droit d'être là :(</span> : !loadings.squad &&
                    <div>
                        <h1 style={{fontSize: "3em"}}>{squad.title}</h1>
                        <div className="default_text_black" style={{width: '50%', margin: 'auto', marginBottom: '30px'}}>
                            {squad.description}
                        </div>
                        {isModerator &&
                            <span>
                                <button style={{background: '#c00'}} className="button_form" onClick={(e) => confirmDelete(e)}>Supprimer</button>

                                <button style={{background: '#223686'}} className="button_form" onClick={() => copyLink()}>
                                    {link.copied ? 'Copié!' : 'Copier le lien'}<LinkIcon style={{paddingLeft: '3px'}}/>
                                </button>

                            </span>
                        }
                        <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                        <h1>Trajets {squad.goingAndComing && "aller"}</h1>
                        {tripsGoing.length === 0 && <div className="default_text_black">Aucun trajet correspondant</div>}
                        {!loadings.going ? <ListTrips listTrips={tripsGoing} addButton={() => handleNewTrip("going")}/> : <span>Chargement...</span>}
                        {
                            squad.goingAndComing &&
                            <div>
                                <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                                <h1>Trajets retour</h1>
                                {tripsComing.length === 0 && <div className="default_text_black">Aucun trajet correspondant</div>}
                                {!loadings.coming ? <ListTrips listTrips={tripsComing} addButton={() => handleNewTrip("coming")}/> : <span>Chargement...</span>}

                            </div>
                        }
                    </div>
                }
            </div>
        </div>

    )
}

export default DetailsSquad;

