import Trip from './../components/trip.js'
import './styles/list_trips.css';
import AddIcon from '@material-ui/icons/Add';

const ListTrips = ({detailsButton=true, addButton=null, ...props}) => {


    return (
        <div className="listtrips">
            {

                props.listTrips.map((trip) => {
                    return <Trip data={trip} key={trip["id"]} detailsButton={detailsButton}/>
                })
            }
            {addButton && <button className="addButton" onClick={addButton} ><span><AddIcon className="icon"/></span></button>}
        </div>
    )

}

export default  ListTrips ;