 const local_time_to_UTC = (date, time) => {
    const localDate = new Date();
    const nbS = Date.parse(date+'T'+time+':00')
    localDate.setTime(nbS)

    const utcDate = new Date();
    utcDate.setTime(Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(),localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds()));

    var month = '' + (utcDate.getMonth() + 1);
    var day = '' + utcDate.getDate();
    const year = utcDate.getFullYear();

    var hour = '' + utcDate.getUTCHours();
    var minute = '' + utcDate.getUTCMinutes();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    if (minute.length <2){
        minute = '0' + minute;
    }
    if (hour.length <2){
        hour = '0' + hour;
    }
    return {date:[year, month, day].join('-'), time:hour+':'+minute};
}

const UTC_to_local_time = (dateStringUTC) => {
    const local = new Date(dateStringUTC);
    let ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(local);
    let mo = new Intl.DateTimeFormat('fr', { month: 'long' }).format(local);
    let da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(local);
    let h = new Intl.DateTimeFormat('fr', { hour: '2-digit' }).format(local);
    let m = new Intl.DateTimeFormat('fr', { minute: '2-digit' }).format(local);

    h = h.replace(' ','');
    if(m.length<2){
        m = '0' + m;
    }
    return [`${da} ${mo} ${ye}`, `${h}${m}`];
}

const verifyConnection = ({data, nextPage = null, triggerOK = null,  triggerError = null, paramsOK=null, paramsError=null}) => {
    if ("status" in data && data["status"] === 401) {
        window.location.href = (process.env.REACT_APP_API_URL + process.env.REACT_APP_LOGIN_ROUTE);
    } else if ("status" in data && data["status"] === 200) {
        if(triggerOK){
            triggerOK(paramsOK);
        }
        if(nextPage){
            window.location.href = nextPage;
        }
        return true;
    } else {
        if(triggerError){
            triggerError(paramsError);
        }
    }


}
export {local_time_to_UTC, UTC_to_local_time, verifyConnection};
