import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Page404 from './views/page404'
import Profile from './views/profile'
import MyTrips from './views/myTrips'
import MySquads from './views/mySquads'
import Propose from './views/propose'
import Search from './views/search';
import Welcome from './views/welcome';
import Details from './views/details';
import DetailsSquad from './views/detailsSquad'


const App = () => {

  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Switch>
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/myTrips' component={MyTrips} />
          <Route exact path='/propose' component={Propose} />
          <Route exact path='/search' component={Search} />
          <Route path='/mySquads' component={MySquads} />
          <Route path='/trip/:idTrip' component={Details} />
          <Route path='/squad/:idSquad' component={DetailsSquad} />
          <Route exact path='/' component={Welcome} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </div >
  );
}

export default App;
