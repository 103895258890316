import { useEffect, useState, React } from "react";
import './styles/banner.css';
import Logo from './../static_image/VRoum_logo_bleu_inv.svg';
import logoutLogo from './../static_image/logout.png';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { verifyConnection } from './../utils.js'
import { withRouter } from "react-router";


const Banner = ({...props}) => {
    const [user, setUser] = useState({});

    useEffect( () => {
        const url = process.env.REACT_APP_API_URL+'/user/me'
        fetch(url, {method: 'GET', credentials: 'include', mode:'cors'})
        .then(function(response) {
            return response.json();

        })
        .then(function(data){
            if(verifyConnection({data})){
                setUser(data.data)
                if (props.verifyUser){
                    props.verifyUser(data.data["id"]);
                }
            }
        })
        .catch(err => console.log(err));

    }, []);

    const logout = () => {
        //insérer requête get sur 'auth/logout'
        const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_LOGOUT_ROUTE;
        // console.log("Vous êtes déconnecté");
        window.location.href = (url);
    }

    const changePage = (page) => {
        props.history.push('/' + page);
    }

    const showTopNavMenu = props.showTopNavMenu

    const showNav= {
        display: showTopNavMenu ? 'flex' : 'none'
      }
    const showMenuIcon = {
        display: showTopNavMenu ? 'none' : 'inline-block',
        transform: 'scale(1.5)',
      }

    const options = [
        'Profil',
        'Mes trajets',
        'Nouveau trajet',
        'Squad Trip',
        'Trouver un trajet',
      ];

    const trad = {
        'Nouveau trajet':'propose',
        'Trouver un trajet': 'search',
        'Squad Trip': 'mySquads',
        'Mes trajets':'myTrips',
        'Profil':'profile',
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
      };

function createRipple(event) {
    const button = event.currentTarget;

    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  }

  const button_list = document.getElementsByClassName("nav");
  for (const button of button_list) {
    button.addEventListener("click", createRipple);
  }



    return (
        <nav id={props.id} className="nav_in_banner">
            <a className='nav_logo' href='/'>
                <img id='logo' alt="VRoum Logo" src={Logo}/>
                <h1 id='titre'>VR<span className="little">oum</span></h1>

            </a>
            <ul className='nav_button'>
                <li className="nav" onClick={() => changePage("profile")} style={showNav}>
                    Profil
                    </li>
                <li className="nav" onClick={() => changePage("myTrips")} style={showNav}>
                    Mes trajets
                    </li>
                <li className="nav" onClick={() => changePage("propose")} style={showNav}>
                    Nouveau trajet
                    </li>
                <li className="nav" onClick={() => changePage("mySquads")} style={showNav}>
                    Squad Trip
                    </li>
                <li className="nav" onClick={() => changePage("search")} style={showNav}>
                    Trouver un trajet
                    </li>
                {user["firstName"]&& <li className="user_button" onClick={() => changePage("profile")}>
                        <div className="fullName" >{user["firstName"]} {user["lastName"]}</div><div className="userIcon"><AccountCircleIcon /></div><img className="logout" alt="logout" onClick={() => logout()} src={logoutLogo} />
                </li>}
            </ul>
            <div id="div_menu_responsive" style={showMenuIcon}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <MenuIcon style={{fill: 'white'}}/>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}>

                    {options.map((option) => (
                        <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => props.onClick(trad[option])}>
                        {option}
                        </MenuItem>
                    ))}
                </Menu>
            </div>

        </nav>
    )

}

export default withRouter(Banner);