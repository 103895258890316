import User from './../components/user.js'
import './styles/list_users.css';

const ListUsers = (props) => {


    return (
        <div className="listusers">
            {

                props.listUsers.map((user, index) => {
                    return <User data={user} key={user["id"]} index={index}/>
                })
            }

        </div>
    )

}

export default  ListUsers ;