import './styles/loading.css';
import Logo from './../static_image/VRoum_logo_bleu_inv.svg';

const Loading  = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', margin: '0 auto', background: '#fffc', padding: '20px'}}>
            <div className="logospin" style={{background: 'white', height: '5rem', width: '5rem', borderRadius: '50%',padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center',boxShadow: '0 0 10px #000c'}}>
                <img src={Logo} alt="Logo VRoum" style={{height: '3rem'}} />
            </div>
            <h2 style={{color: '#000', border: 'none'}}>Chargement...</h2>
        </div>
    );


}

export default Loading;