import React, { useState, useEffect } from 'react';
import Banner from './../components/banner.js';
import ListTrips from './../components/list_trips.js';
import './view.css';
import {verifyConnection} from './../utils.js'

const Search = (props) => {
    const [listTrips, setListTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listTripsComplete, setListTripsComplete] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(true);

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + '/trip/available'
        // console.log("fetching to", url)
        fetch(url, { method: 'GET', credentials: 'include' })
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data.data)
                if (verifyConnection({data})){
                    setListTrips(data.data);
                    setLoading(false);
                }

            })
            .catch(err => console.log(err));

    }, []);

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + '/trip/complete'
        // console.log("fetching to", url)
        fetch(url, { method: 'GET', credentials: 'include' })
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data.data)
                if (verifyConnection({data})){
                    setListTripsComplete(data.data);
                    setLoadingComplete(false);
                }

            })
            .catch(err => console.log(err));

    }, []);

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    })

    return (
        <div className='image_campus'>
            <Banner showTopNavMenu={width > 775} id="nav_in_banner" />
            <div className='white_layer'>
                <br/><br/>
                <h1 className="main">Trajets disponibles</h1>
                <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                <div>
                    {loading && <div className="default_text_black">Chargement...</div>}
                    {(!loading && listTrips.length === 0) ? <div className="default_text_black">Désolé il n'y a aucun trajet qui correspond à ta recherche :(</div> : !loading && <ListTrips listTrips={listTrips} detailsButton={true} />}

                </div>
                {(!loadingComplete && listTripsComplete.length !== 0) &&
                    <div>
                        <h1 className="main">Trajets complets</h1>
                        <hr style={{ width: '80%', margin: '15px 10%', height: '2px', background: 'gray' }} />
                        <div>
                        <ListTrips listTrips={listTripsComplete} detailsButton={true} />
                        </div>
                    </div>}
            </div>
        </div>
    );

}

export default Search;
