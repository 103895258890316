import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert'; // Import
import './styles/car.css';
import { verifyConnection } from '../utils';
import DriveEtaIcon from '@material-ui/icons/DriveEta';



const Car  = (props) => {
    const data = props.data;

    const confirmDelete = (e) => {
        confirmAlert({
            title: 'Confirme la suppression',
            message: 'Veux tu vraiment supprimer cette voiture?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => deleteThisCar(e),
              },
              {
                label: 'Non',
                onClick: () => {},
              }
            ]
          });
    };

    const deleteThisCar = () => {
        const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken'))
        .split('=')[1];
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  'X-CSRFToken': csrfToken},
            credentials: 'include'
        };


        fetch(process.env.REACT_APP_API_URL+'/car/unsaveCar/'+data["id"]+'/', requestOptions)
            .then(function(response){return response.json()})
            .then(function(data){
                verifyConnection({data, triggerOK:props.reloadFunction, paramsOK : true});
            } );
    }

    return (
        <div className="car">
                <div className="barre_haut"><span>{data["model"]} | {data["color"]}</span><DriveEtaIcon /></div>
                    <div className="contenu_car">
                        Immatriculation : XX - XXX - {data["immatriculation"].toUpperCase()}
                        <br></br>
                        {props.displayPlace && <span>{data["place_available"]} place(s) passager</span>}
                        <br></br>
                        {props.deleteButton && <button className="button_delete" onClick={(e) => confirmDelete(e)}>Supprimer</button>}
                    </div>


        </div>
    );


}

export default Car;