import React, { useEffect, useState } from 'react';
import Banner from './../components/banner.js';
import ListCars from './../components/list_cars.js';
import './view.css';
import {verifyConnection} from './../utils.js';
import LoadingComponent from './../components/loading.js';

const Profile = (props) => {
    const [modifying, setModifying] = useState(false);
    const [user, setUser] = useState({});
    const [confirmation, setConfirmation] = useState(false);
    const [newCar, setNewCar] = useState({ model: '', color: '', saved: true, immatriculation: '', place_available: 1 });
    const [listCars, setListCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);

    const handleCloseConfirmation = () => {
        setConfirmation(false);
    }

    const submit = (e) => {
        e.preventDefault()//pour que ça ne refersh pas la page
        setModifying(false);
        // console.log("envoi de :", user, "à ", process.env.REACT_APP_API_URL + '/user/me/updateProfile/');
        const csrfToken = document.cookie
            .split(';')
            .find(row => row.startsWith('csrftoken'))
            .split('=')[1];
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
            body: JSON.stringify(user),
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_API_URL + '/user/updateProfile/', requestOptions)
            .then(response => response.json())
            .then(function (data) {
                verifyConnection({data, triggerOK:setConfirmation, paramsOK : true});
            })
            .catch((err) => console.log(err));
    }

    const updateForm = (e) => {
        e.preventDefault()//pour que ça ne refersh pas la page
        setModifying(true)

    }
    const handleFormUpdate = (event, key) => {
        setUser(pvevUser => {
            var updatedValues = {};
            updatedValues[key] = event.target.value;
            return { ...pvevUser, ...updatedValues };
        });
    }

    const handleNewCar = (event, key, value = null) => {
        setNewCar(prevForm => {
            var updatedValues = {};
            if (value) {
                updatedValues[key] = value;
            } else {
                updatedValues[key] = event.target.value;
                if (!(key === 'immatriculation' && updatedValues.immatriculation.length > 2)) {
                    return { ...prevForm, ...updatedValues };
                } else {
                    return prevForm;
                }
            }

            return { ...prevForm, ...updatedValues };
        });
    }

    const handleSaveNewCar = (e) => {
        e.preventDefault()//pour que ça ne refersh pas la page
        const url = process.env.REACT_APP_API_URL + '/car/createCar/';
        // console.log("enregistrement de :", newCar, "à ", url);
        const csrfToken = document.cookie
            .split('; ')
            .find(row => row.startsWith('csrftoken'))
            .split('=')[1];
        setNewCar(prevForm => { return { ...prevForm, immatriculation: prevForm.immatriculation.toUpperCase() } })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
            body: JSON.stringify(newCar),
            credentials: 'include'
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(function (data) {
                // console.log("Response:", data.data)
                verifyConnection({data, triggerOK: setReload, paramsOK : true});
            })
            .catch((err) => console.log(err))
    }


    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + '/user/me' //ajouter un param booléen pour indiquer quon veut que l'user logged (si il est pas logged il sera renvoyé sur l'auth dtf)
        // console.log("fetching to", url)
        fetch(url, { method: 'GET', credentials: 'include', mode: 'cors' })
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data)
                verifyConnection({data, triggerOK: setUser, paramsOK : data.data});
            } )
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        setReload(false);
        const url2 = process.env.REACT_APP_API_URL + '/user/carsSaved/me';
        // console.log("fetching to", url2)
        fetch(url2, { method: 'GET', credentials: 'include', mode: 'cors' })
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data.data);
                if(verifyConnection({data})){
                    setListCars(data.data);
                    setLoading(false);
                }
            })
            .catch(err => console.log(err));



    }, [reload]);

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    })

    return (
        <div className='image_campus'>
            <Banner showTopNavMenu={width > 775} id="nav_in_banner"/>
            {user === {} ? <LoadingComponent /> :
            <div className='white_layer'>
                <br />
                <div className="AlerteOK" style={confirmation ? {display: "block"} : {display: "none"}}>
                    <button onClick={() => handleCloseConfirmation()}>X</button>
                    <span className="default_text_black">Tes modifications ont bien été enregistrées !</span>
                </div>
                <h1 className="main">Mon Profil</h1>
                <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                <form>
                    <div className="default_text_black">{user["firstName"]} <b>{user["lastName"]}</b><br/><br/></div>
                    <div className={modifying ? "Modified_form" : "NonModified_form"}>
                        <label className="default_text_black">Tél : </label>
                        {user["phone"] !== null ? <input disabled={modifying ? "" : "disabled"} value={user["phone"]} onChange={(event) => handleFormUpdate(event, "phone")} /> : <input disabled={modifying ? "" : "disabled"} placeholder="Non rempli" onChange={(event) => handleFormUpdate(event, "phone")} />}
                        <br></br><br></br>
                        <label className="default_text_black">Mail : </label>
                        <input disabled={modifying ? "" : "disabled"} value={user !== {} ? user["email"] : "Chargement..."} onChange={(event) => handleFormUpdate(event, "email")} />
                        <br></br>
                        <button className="button_form"  disabled={modifying ? "" : "disabled"} onClick={(e) => submit(e)}>Enregistrer</button>
                        <button className="button_form"  disabled={modifying ? "disabled" : ""} onClick={(e) => updateForm(e)}>Modifier</button>
                    </div>
                </form>

                <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                <h2 className="titre">Mes voitures enregistrées</h2>
                {loading && <div className="default_text_black">Chargement...</div>}
                {!loading && listCars.length === 0 ? <div className="default_text_black">Aucune voiture</div> : !loading && <ListCars listcars={listCars} argsSup={() => setReload()} />}
                <div id="carac_car">
                    <h2 className="titre">Nouvelle voiture</h2>
                    <form>
                        <div className="field">
                            <label className="default_text_black">Modèle : </label>
                            <input id="demo-customized-textbox" value={newCar["model"]} onChange={(event) => handleNewCar(event, "model")} placeholder="Citroën C3"/>
                        </div>
                        <br></br>
                        <div className="field">
                        <label className="default_text_black">Couleur : </label>
                            <input id="demo-customized-textbox" value={newCar["color"]} onChange={(event) => handleNewCar(event, "color")} placeholder="Grise"/>
                        </div>
                        <br></br>
                        <div className="field">
                            <label className="default_text_black">2 dernières lettres de l'immatriculation : </label>
                            <input id="demo-customized-textbox" value={newCar["immatriculation"].toUpperCase()} onChange={(event) => handleNewCar(event, "immatriculation")} placeholder="AF" />
                        </div>
                        <br></br>
                        <div className="field">
                            <label className="default_text_black"> Places libres dans la voiture : </label>
                            <input type='number' step="1" min="1" defaultValue={3} value={newCar["place_available"]} onChange={(event) => handleNewCar(event, "place_available")} />
                        </div><br/>
                        <button style={{background: 'rgb(0, 157, 0)', cursor: 'pointer'}} className="button_form" onClick={(e) => handleSaveNewCar(e)}>Ajouter</button>
                    </form>
                </div>
            </div>
            }
        </div>
    )

}

export default Profile;