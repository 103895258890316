import React, { useEffect, useState } from 'react';
import Banner from '../components/banner.js';
import ListSquads from '../components/list_squads';
import './view.css';
import { makeStyles} from '@material-ui/core/styles';
import { verifyConnection } from '../utils.js'
import LoadingComponent from '../components/loading';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const MySquads = (props) => {
    const [loadings, setLoadings] = useState(true);
    const [mySquads, setMySquads] = useState([]);
    const [formState, setForm] = useState({ title: "", date: "", description: "",  goingAndComing: false, moderators:""});

    const classes = useStyles();
    const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken'))
    .split('=')[1];

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        };
        const url = process.env.REACT_APP_API_URL + '/user/squad/me';//Fetch the user's squad participation
        fetch(url, requestOptions)
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                if (verifyConnection({ data })) {
                    setMySquads(data.data);
                    setLoadings(false);
                }


            })
            .catch(err => console.log(err));
    }, [])

    const [width, setWindowWidth] = useState(0);
    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }
    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    })

    const handleFormUpdate = (event, key, value = null) => {
        setForm(prevForm => {
            if(key === "goingAndComing") {
                return { ...prevForm, goingAndComing: !prevForm.goingAndComing }
            } else {
                var updatedValues = {};
                updatedValues[key] = event.target.value;
                return { ...prevForm, ...updatedValues };
            }

        });
    }

    const submit = (e) => {
        e.preventDefault()//pour que ça ne refresh pas la page
        const formToSend = formState;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
            body: JSON.stringify(formToSend),
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_API_URL + '/squad/createSquad/', requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if ("status" in data && data["status"] === 400) {
                    //Verify form et surlignage rouge là où il faut
                }
                verifyConnection({data, nextPage:(process.env.REACT_APP_CLIENT_URL + '/squad/' + String(data["id"]))});
            })
            .catch((err) => console.log(err))


    }

    return (
        <div className='image_campus'>
            <Banner showTopNavMenu={width > 775} id="nav_in_banner"/>
            {loadings["passenger"] || loadings["driving"] ?
            <LoadingComponent />
            :
            <div className='white_layer'>
                <div>
                    <br/>
                    <h1>Les Squad Trips auxquels je participe</h1>
                    {mySquads.length === 0 ? <div className="default_text_black">Rien à voir ici :(</div> : !loadings["passenger"] && <ListSquads listSquads={mySquads} detailsButton={true} />}
                    <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                    <br/>
                    <h1>Créer un nouveau Squad Trip</h1>

                    <form>

                        <div className="block_form_vert">
                            <label className="default_text_black">Titre</label>
                            <input className='input_text' placeholder="WEC De ViaRézo" type='text' value={formState["title"]} onChange={(event) => handleFormUpdate(event, "title")} />
                        </div>


                        <div className="block_form_vert">
                            <label className="default_text_black">Date</label>
                            <div className="wrap_input">
                                <input id="date" type="date" color="primary" className={classes.textField} InputLabelProps={{ shrink: true, }} value={formState["date"]} onChange={(event) => handleFormUpdate(event, "date")} />
                            </div>
                        </div>

                    <div className="block_form_hori">
                        <label className="default_text_black">Ajouter le voyage de retour ?</label>
                        <input type="checkbox"  checked={formState["goingAndComing"]} onChange={(event) => handleFormUpdate(event, "goingAndComing")} />
                    </div>
                    <br /><br />
                    <div className="block_form_vert multilineBlock">
                            <label className="default_text_black">Description (plages horaires de départ, nombre de voitures nécessaire etc.)</label>
                            <textarea id="multiline" placeholder="" className='input_text' value={formState["description"]} onChange={(event) => handleFormUpdate(event, "description")} />
                    </div>
                    <div className="block_form_vert">
                        <label className="default_text_black">Modérateurs (séparer par des ;)</label>
                        <input className='input_text' placeholder="2020nomp;2021nompr" type='text' value={formState["moderators"]} onChange={(event) => handleFormUpdate(event, "moderators")} />
                    </div>

                    <div>
                        <button className="button_form" onClick={(e) => submit(e)}>
                            Enregistrer
                        </button>
                    </div>
                </form>
                </div>
            </div>}
        </div>
    )

}

export default MySquads;
