import Car from './../components/car.js'
import './styles/list_cars.css';

const ListCars = ({deleteButton=true, displayPlace=true, ...props}) => {
    const getPositionInGrid = (i) => {
        return "grid-column: "+String(i%4+1)+"; grid-row: "+String(Math.floor(i/4+1)+1)+";";
    }

    return (
        <div className="listcars">
            {

                props.listcars.map((car, index) => {
                    return <Car data={car} key={car["id"]} deleteButton={deleteButton} displayPlace={displayPlace} reloadFunction={() => props.argsSup()} style={getPositionInGrid(index)}/>
                })
            }

        </div>
    )

}

export default  ListCars ;