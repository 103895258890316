import React from 'react';
import Banner from './../components/banner.js';
import { useEffect, useState } from "react";
import './welcome.css';
import './view.css';
import Trip from './../components/trip.js';
import {verifyConnection} from './../utils.js'
import Logo from './../static_image/VRoum_logo_bleu_inv.svg';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const WelcomePage = (props) => {
    
    const [width, setWindowWidth] = useState(0);
    const [listTrips, setListTrips] = useState([]);
    const [loading, setLoading] = useState(true);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const handlePageChange = (page) => {
        // console.log("On va à la page: " + page)
        props.history.push("/" + page)
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    })

    useEffect(() => {
        const url = process.env.REACT_APP_API_URL + '/trip/caroussel'
        // console.log("fetching to", url)
        fetch(url, { method: 'GET', credentials: 'include' })
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data.data)
                if (verifyConnection({data})){
                    setListTrips(data.data);
                    setLoading(false);
                }

            })
            .catch(err => console.log(err));

    }, []);

    return (

        <div id="image_campus_welcome" >
            <Banner onClick={(page) => handlePageChange(page)} showTopNavMenu={width > 775 } id="nav_in_banner" />
            <div className="fullcenter">
                <img id='logo' alt="VRoum Logo" src={Logo}/>
                <h1 id='presentation_titre'>VR<span className="little">oum</span></h1>
                <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                <p id='presentation_slogan'>Le service de covoiturage de ViaRézo</p>
                <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                <div className='button_material' id='button_welcome_propose'>
                    <button onClick={() => handlePageChange("propose")}>
                        <AddCircleIcon style={{ marginRight: '10px' }} /><span>Proposer un trajet</span>
                    </button>
                </div>
                <div className='button_material' id='button_welcome_propose'>
                    <button onClick={() => handlePageChange("search")} style={{background: 'green'}}>
                     <SearchIcon style={{ marginRight: '10px', height: '30px' }} /><span>Trouver un trajet</span>
                    </button>
                </div>
                {(!loading && listTrips.length!==0) && <div id="carrousel">
                    {listTrips.map((trip) =>  <div key={trip["id"]} className="c_items"><Trip data={trip} key={trip["id"]} detailsButton={true}/></div>)}
                    </div>
                }
            </div>
        </div>

    )
}

export default WelcomePage;

