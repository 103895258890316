import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './styles/trip.css';
import { UTC_to_local_time } from './../utils.js'
import { withRouter } from "react-router";



const Trip = (props) => {
    const data = props.data;
    const details = () => {
        props.history.push("/trip/" + data["id"]);
    }

    const localDateS = UTC_to_local_time(data["date"]);
    return (
        <div className="trip">
            <div className="barre_haut">
                <ul className="places default_text_black">
                    {data["departure"]}
                </ul>
                <span style={{display: "inline-flex", width: "100%", flexDirection: 'row', alignItems: "center",justifyContent: 'space-between'}}>
                    <hr style={{height: '2px', flexGrow: 1, background: '#fff'}} />
                    <span style={{flexGrow: 0, margin: '10px'}}>À</span>
                    <hr style={{height: '2px', flexGrow: 1, background: '#fff'}} />
                </span>
                <ul className="places default_text_black">
                    {data["destination"]}
                </ul>
            </div>
            <div className="contenu">
                <ul>
                    Le : {localDateS[0]}
                </ul>
                <ul className="separator"></ul>
                <ul>
                    À :  {localDateS[1]}
                </ul>
                <ul className="separator"></ul>
                {data["place_available"] - data["passengers"].length > 0 ?
                    <ul>
                        {data["place_available"] - data["passengers"].length} places disponibles
                    </ul>
                    :
                    <ul>
                        COMPLET
                    </ul>}
                <ul className="separator"></ul>
                <ul>
                    Prix : {data["price"]} €
                </ul>
                <ul className="separator"></ul>
                {data["description"].length > 0 && <ul className="description">
                    Description : {data["description"].length < 200 ||  !props.detailsButton ? data["description"] : data["description"].slice(0, 200)+'...'}
                </ul>}
                {props.detailsButton && <button className={"button_paps"} onClick={(e) => details(e)}>Détails du trajet</button>}
            </div>
        </div>
    );


}

export default withRouter(Trip);