import React, { useEffect, useState } from 'react';
import Banner from './../components/banner.js';
import ListTrips from './../components/list_trips.js';
import './view.css';
import {verifyConnection} from './../utils.js'
import LoadingComponent from './../components/loading';


const MyTrips = (props) => {
    const [loadings, setLoadings] = useState({ drivings: true, passenger: true });
    const [myDriving, setMyDriving] = useState([]);
    const [myTrips, setMyTrips] = useState([]);

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        };
        const url = process.env.REACT_APP_API_URL + '/user/driver/me';//Fetch the user's drivings
        // console.log("fetching to", url)
        fetch(url, requestOptions)
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data.data)
                if (verifyConnection({data})) {
                    setMyDriving(data.data);
                    setLoadings((prevState) => { return { ...prevState, drivings: false } });
                }


            })
            .catch(err => console.log(err));

        const url2 = process.env.REACT_APP_API_URL + '/user/passenger/me';//Fetch the user's trips as a passenger
        // console.log("fetching to", url2)
        fetch(url2, requestOptions)
            .then(function (response) {
                return response.json();

            })
            .then(function (data) {
                // console.log("Fetched:", data.data)
                if (verifyConnection({data})){
                    setMyTrips(data.data);
                    setLoadings(prevState => { return { ...prevState, passenger: false } });
                }



            })
            .catch(err => console.log(err));

    }, [])

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    })

    return (
        <div className='image_campus'>
            <Banner showTopNavMenu={width > 775} id="nav_in_banner"/>
            {loadings["passenger"] || loadings["driving"] ?
            <LoadingComponent />
            :
            <div className='white_layer'>
                <div>
                    <br/>
                    <h1>Mes trajets (passager)</h1>
                    {myTrips.length === 0 ? <div className="default_text_black">Aucun trajet correspondant</div> : !loadings["passenger"] && <ListTrips listTrips={myTrips} depapsButton={true} />}

                    <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                    <h1>Mes trajets (conducteur)</h1>
                    {myDriving.length === 0 ? <div className="default_text_black">Aucun trajet correspondant</div> : !loadings["drivings"] && <ListTrips listTrips={myDriving} deleteButton={true} />}

                    <hr style={{width: '80%', margin: '15px 10%', height: '2px', background: 'gray'}}/>
                </div>
            </div>}
        </div>
    )

}

export default MyTrips;
