import React, {  useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Banner from './../components/banner.js';
import ListTrips from './../components/list_trips.js';
import ListCars from './../components/list_cars.js';
import ListUsers from './../components/list_users.js';

import './view.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import { verifyConnection } from '../utils.js';



const Details = (props) => {
    const {idTrip} =useParams();
    const [trip, setTrip] = useState({});
    const [driver, setDriver] = useState({});
    const [car, setCar] = useState({});

    const [passengers, setPassengers] = useState([]);
    const [loadings, setLoadings] = useState({trip: true, passengers: true, driver:true, car:true})
    const [button, setButton] = useState({driver: false, passenger: false, available: false})
    const [userId, setUserID] = useState(-1);
    const [reload, setReload] = useState(false);

    const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken'))
        .split('=')[1];

    const confirmDelete = (e) => {
        confirmAlert({
            title: 'Confirme la suppression',
            message: 'Veux tu vraiment supprimer ce trajet? N\'oublie pas de prévenir tes passagers !',
            buttons: [
              {
                label: 'Oui',
                onClick: () => deleteTrip(e),
              },
              {
                label: 'Non',
                onClick: () => {},
              }
            ]
          });
    };

    const confirmDepaps = (e) => {
        confirmAlert({
            title: 'Confirme le depaps',
            message: 'Veux tu vraiment annuler ta participation à ce trajet en tant que passager? N\'oublie pas de prévenir le conducteur!',
            buttons: [
              {
                label: 'Oui',
                onClick: () => depaps(e),
              },
              {
                label: 'Non',
                onClick: () => {},
              }
            ]
          });
    };


    const deleteTrip = () => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json',  'X-CSRFToken': csrfToken},
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_API_URL+'/trip/deleteTrip/'+String(idTrip)+'/', requestOptions)
            .then(function(response){return response.json()})
            .then(function(data){
                verifyConnection({data, nextPage: (process.env.REACT_APP_CLIENT_URL+'/myTrips')});
            } );
    }

    const depaps = () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  'X-CSRFToken': csrfToken},
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_API_URL+'/trip/depaps/'+String(idTrip)+'/', requestOptions)
            .then(function(response){return response.json()})
            .then(function(data){
                verifyConnection({data, nextPage: (process.env.REACT_APP_CLIENT_URL+'/myTrips')});
            } );
    }

    const paps = () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  'X-CSRFToken': csrfToken},
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_API_URL+'/trip/paps/'+String(idTrip)+'/', requestOptions)
            .then(function(response){return response.json()})
            .then(function(data){
                verifyConnection({data, nextPage: (process.env.REACT_APP_CLIENT_URL+'/myTrips'), triggerOK: setReload, paramsOK: true});
            } );
    }

    useEffect(() => {
        getTrip()
        .then((args) => {
            getDriver(args[0]);
            getCar(args[1]);
            getPassengers();
        })}, [reload]);


    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        credentials: 'include'
    };


    const getTrip = () => {
        return new Promise((resolve, reject) => {
            setReload(false);
            const url = process.env.REACT_APP_API_URL+'/trip/details/'+String(idTrip)+'/';//Fetch the trip details
            // console.log("fetching to", url)
            fetch(url, requestOptions)
            .then(function(response) {
                return response.json();

            })
            .then(function(data){
                //console.log("Fetched:",data.data)
                if (verifyConnection({data, triggerError: reject})){
                    setTrip(data.data);
                    setLoadings((prevState) => {return {...prevState, trip:false}});
                    resolve([data.data["driver"], data.data["car"]]);
                }
            });
        });
    };


    const getDriver = (pk) => {
        const url = process.env.REACT_APP_API_URL+'/user/details/'+String(pk)+'/';//Fetch the user details
        // console.log("fetching to", url)

        fetch(url, requestOptions)
        .then(function(response) {
            return response.json();

        })
        .then(function(data){
            // console.log("Fetched:",data.data)
            if (verifyConnection({data, triggerError: () => {console.log("ce user n'existe plus", data["driver"])}})){
                setDriver(data.data);
                setLoadings((prevState) => {return {...prevState, driver:false}});
            }
        })
    }

    const getCar = (idCar) => {
        const url = process.env.REACT_APP_API_URL+'/car/details/'+String(idCar)+'/' ;//Fetch the car details
        // console.log("fetching to", url)

        fetch(url, requestOptions)
        .then(function(response) {
            return response.json();

        })
        .then(function(data){
            // console.log("Fetched:",data.data)
            if (verifyConnection({data})){
                setCar(data.data);
                setLoadings((prevState) => {return {...prevState, car:false}});
            }
        }).catch(err => console.log(err))
    }
    const getPassengers = () => {
            const url = process.env.REACT_APP_API_URL+'/trip/passengers/'+String(idTrip)+'/' ;//Fetch the passangers details
            // console.log("fetching to", url)

            fetch(url, requestOptions)
            .then(function(response) {
                return response.json();

            })
            .then(function(data){
                // console.log("Fetched:",data.data)
                if (verifyConnection({data})){
                    setPassengers(data.data);
                    setLoadings((prevState) => {return {...prevState, passengers:false}});
                }
            }).catch(err => console.log(err))

        }

    const verifyUser = (id) => {
        setUserID(id)
    }

    useEffect(()=> {
        if(trip["driver"]!==undefined && trip["passengers"]!==undefined){
            if (trip["driver"]===userId){
                setButton((prevState) => { return {passenger: false, driver: true, available: false}})
            } else if (trip["passengers"].includes(userId)){
                setButton((prevState) => { return {passenger: true, driver: false, available: false}})
            } else if (trip["passengers"].length < trip["place_available"]) { //Add le critère sur la date et tout les critères qu'on applique dans le back à la view available des trips ou alors get les trajets available et vérifier qu'ils sont dedans
                setButton((prevState) => { return {passenger: false, driver: false, available: true}})
            } else {
                setButton((prevState) => { return {passenger: false, driver: false, available: false}})
            }
        }

    }, [userId, trip]);

    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
        }

    useEffect( () => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize',updateDimensions);
    })



    return (

        <div className='image_campus'>
            <Banner verifyUser={(args) => verifyUser(args)} showTopNavMenu = {width > 775} id="nav_in_banner"/>
            <div className="white_layer">
                <div className={width > 780 ? "hori_align" : "responsive_hori_align"}>

                    <div className="vert_block driver">
                        <h2>Le conducteur</h2>
                        {loadings["driver"] && <div className="default_text_black">Chargement...</div>}
                        {!loadings["driver"] && <ListUsers className="userList" listUsers= {[driver]}/>}
                    </div>

                    <div className="vert_block block_trip">
                        <h1>Le trajet</h1>
                        {loadings["trip"] && <div className="default_text_black">Chargement...</div>}
                        {!loadings["trip"] && <ListTrips listTrips= {[trip]} detailsButton={false}/>}
                    </div>

                    <div className="vert_block block_car">
                        <h2>La voiture </h2>
                        {loadings["car"] && <div className="default_text_black">Chargement...</div>}
                        {!loadings["car"] && <ListCars listcars= {[car]} deleteButton={false} displayPlace={false}/>}
                    </div>

                </div>

                <div className="hori_block passengers">
                        <h2>Les passagers {!loadings["passengers"] &&  <div> ({passengers.length}/{trip["place_available"]})</div>} </h2>
                        {loadings["passengers"] && <div className="default_text_black">Chargement...</div>}
                        {!loadings["passengers"] && <ListUsers className="userList" listUsers= {passengers}/>}
                </div>

                {button["driver"] && <button style={{background: '#c00'}} className="button_form" onClick={(e) => confirmDelete(e)}>Supprimer</button>}
                {button["passenger"] && <button style={{background: '#c00', fontSize: '2em'}} className="button_form" onClick={(e) => confirmDepaps(e)}>DEPAPS</button>}
                {button["available"] && trip.place_available - passengers.length>0 && <button style={{ background: '#0c0' }} className="button_form" onClick={(e) => paps(e)}>PAPS</button>}
                {button["available"] && !(trip.place_available - passengers.length>0) && <button style={{ background: '#0c0' }} className="button_form" disabled="true">COMPLET</button>}
            </div>
        </div>

    )
}

export default Details;

